import type { LoaderFunctionArgs } from "@remix-run/node";
import {
  json,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useMatches,
  useRouteError,
} from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { useEffect } from "react";

import { ErrorPage } from "~/components";

const DEBUG = false;

export const loader = async ({ request }: LoaderFunctionArgs) => {
  return json({
    ENV: {
      ENVIRONMENT: process.env.ENVIRONMENT ?? "local",
      RELEASE_NAME: process.env.RELEASE_NAME ?? "dev",
      SENTRY_DSN: process.env.SENTRY_DSN,
    },
  });
};

function App() {
  const { ENV } = useLoaderData<typeof loader>();

  useEffect(() => {
    Sentry.init({
      environment: ENV.ENVIRONMENT,
      release: ENV.RELEASE_NAME,
      dsn: ENV.SENTRY_DSN,
      tracesSampleRate: 1,
      integrations: [
        Sentry.browserTracingIntegration({
          useEffect,
          useLocation,
          useMatches,
        }),
        // Sentry.browserProfilingIntegration(),
        Sentry.feedbackIntegration({
          autoInject: false,
          colorScheme: "system",
          showName: false,
          showEmail: false,
          enableScreenshot: false,
        }),
        // Sentry.replayIntegration({
        //   maskAllText: false,
        //   blockAllMedia: false,
        // }),
      ],

      // replaysSessionSampleRate: 0.,
      // replaysOnErrorSampleRate: 1,
    });
    if (DEBUG) {
      console.log("[App] Sentry initialized");
    }
  }, [ENV.ENVIRONMENT, ENV.SENTRY_DSN, ENV.RELEASE_NAME]);

  return (
    <html>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="shopify-debug" content="web-vitals" />
        <link rel="preconnect" href="https://cdn.shopify.com/" />
        <link
          rel="stylesheet"
          href="https://cdn.shopify.com/static/fonts/inter/v4/styles.css"
        />
        <Meta />
        <Links />
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default Sentry.withSentry(App);

export const ErrorBoundary = () => {
  const error = useRouteError();
  Sentry.captureRemixErrorBoundaryError(error);
  useEffect(() => {
    const feedback = Sentry.getFeedback();
    if (DEBUG) {
      console.log("[App] feedback:", feedback);
    }
    if (feedback) {
      const widget = feedback.createWidget();
      widget.show();
      if (DEBUG) {
        console.log("[App] widget:", widget);
      }
      return () => {
        widget.removeFromDom();
      };
    } else {
      Sentry.init({
        environment: "unknown",
        release: "unknown",
        dsn: "https://98c19c13370c43bbbfdf2ba0fffa6bf3@o4508210906529792.ingest.de.sentry.io/4508223820005456",
        tracesSampleRate: 1,
        integrations: [
          Sentry.feedbackIntegration({
            autoInject: true,
            colorScheme: "system",
            showName: false,
            showEmail: false,
            enableScreenshot: false,
          }),
        ],
      });
      if (DEBUG) {
        console.log("[App] Sentry initialized");
      }
    }
  }, []);

  return (
    <html>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="shopify-debug" content="web-vitals" />
        <link rel="preconnect" href="https://cdn.shopify.com/" />
        <link
          rel="stylesheet"
          href="https://cdn.shopify.com/static/fonts/inter/v4/styles.css"
        />
        <Meta />
        <Links />
      </head>
      <body>
        <ErrorPage />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
};
